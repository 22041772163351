<template>
  <div class="tei-editor-component">
    <div v-b-visible="refreshEditor" class="editor-wrapper">
      <b-button-toolbar class="mb-3">
        <b-btn
          variant="primary"
          class="prettify-action mx-1"
          @click="reformatEditorContent"
        >
          {{ $t("components.tei.reformat") }}
        </b-btn>

        <SpecialCharacterSelect @change="insertCharacter" />

        <ThemeSelect
          :active="currentTheme"
          class="mx-1"
          @change="changeTheme"
        />

        <b-btn
          v-b-toggle.teiHelp1
          :aria-label="$t('components.tei.help.label')"
          variant="primary"
          class="mx-1"
        >
          {{ $t("components.tei.help.button") }}
        </b-btn>
      </b-button-toolbar>

      <b-collapse id="teiHelp1" class="mt-2">
        <b-card :title="$t('components.tei.tagDocumentation')">
          <TeiEditorHintDocumentation
            :tag-info="tagInfo"
            class="tag-documentation"
          />
        </b-card>
      </b-collapse>

      <!-- The TEI/XML editor -->
      <div class="editor-resize-wrapper">
        <div ref="editorpane" class="editor mb-3" @keyup="editorChange" />
      </div>
    </div>
  </div>
</template>

<script>
import CodeMirror from "codemirror";

import "codemirror/mode/xml/xml.js";
import "codemirror/addon/display/placeholder.js";
import "codemirror/addon/edit/closetag.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchtags.js";
import "codemirror/addon/fold/xml-fold.js";
import "codemirror/addon/hint/xml-hint.js";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/selection/active-line.js";

// Styling
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";

// Themes for codemirror
import "codemirror/theme/blackboard.css";
import "codemirror/theme/eclipse.css";
import "codemirror/theme/pastel-on-dark.css";
import "codemirror/theme/base16-light.css";

import beautify from "js-beautify";

export default {
  name: "TeiEditor",
  components: {
    TeiEditorHintDocumentation: () => import("./TeiEditorHintDocumentation"),
    SpecialCharacterSelect: () => import("./SpecialCharacterSelect"),
    ThemeSelect: () => import("./ThemeSelect")
  },
  model: {
    prop: "teiText",
    event: "change"
  },
  props: {
    teiText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editor: Object,
      editorContent: this.teiText,
      formattedContent: null,
      editorHints: {
        //"!top": ["w","name", "milestone", "lb"],
        w: {
          attrs: {
            lemma: [""]
          },
          children: [],
          descr: "Word, common tag to use for any set of runes forming a word"
        },
        pc: {
          descr: "Punctuation, separator between words or sentances"
        },
        gap: {
          attrs: {
            reason: ["lost", "illegible"],
            quantity: Number,
            unit: ["chars", "word", "lines"],
            extent: ["unknown"],
            atleast: Number,
            atmost: Number
          },
          descr: "Runes missing, quantity and reason can be set in attributes"
        },
        supplied: {
          attrs: {
            type: ["clarification", "restoration", "repeated"],
            reason: ["lost", "omitted-in-original"],
            source: [""]
          },
          descr:
            "Supplied, due to reason set in attributes (lost, restoration etc)"
        },
        name: {
          attrs: {
            type: ["person", "place"]
          },
          children: ["w"],
          descr: "Content is considered naming a person or a place"
        },
        damage: {
          attrs: {},
          descr: "Damaged section"
        },
        add: {
          attrs: {
            hand: ["mainscribe", "laterscribe"],
            place: ["inline", "supralinear", "infralinear"]
          }
        },
        orig: {},
        c: {
          attrs: {
            type: ["cipher"],
            subtype: ["twig-rune", "is-rune"],
            rend: [""]
          }
        },
        hi: {
          attrs: {
            rend: ["ligature"]
          }
        },
        lb: {
          attrs: {
            n: Number,
            style: ["text-direction:r-to-l", "text-direction:l-to-r"],
            break: ["no"]
          }
        },
        milestone: {
          attrs: {
            n: [""],
            unit: ["facet", "fragment"]
          }
        },
        app: {
          attrs: {
            type: ["alternative"]
          },
          children: ["rdg"],
          descr: "Wrapper around alternate readings"
        },
        rdg: {
          attrs: {
            source: [""]
          },
          descr: "Alternate readings"
        },
        pb: {
          attrs: {
            n: [""]
          }
        },
        seg: {
          attrs: {
            type: ["latin", "enc"]
          }
        },
        certainty: {
          attrs: {
            degree: Number,
            locus: ["name", "value"]
          }
        }
      }
    };
  },
  computed: {
    currentTheme() {
      return localStorage.getItem("uu.codemirror.theme.name") || "blackboard";
    },
    tagInfo: function() {
      let tagInfo = [];
      for (let tag in this.editorHints) {
        tagInfo.push({
          tag: tag,
          descr: this.editorHints[tag].descr
        });
      }
      return tagInfo;
    }
  },
  watch: {
    teiText: function(newValue) {
      if (this.editorContent != newValue) {
        // got actual new value not just feedback on change
        this.editorContent = newValue;
        this.reformatEditorContent();
      }
    },
    editorContent() {
      this.$emit("change", this.editorContent);
    }
  },
  mounted: function() {
    assignChildrenToWordTag(this.editorHints);
    this.reformatEditorContent();
    this.editor = CodeMirror(this.$refs.editorpane, {
      value: this.formattedContent,
      mode: "xml",
      lineWrapping: true,
      indentUnit: 4,
      theme: this.currentTheme,
      placeholder: this.$t("components.tei.editorPlaceHolder"),
      lineNumbers: true,
      htmlMode: false,
      matchClosing: true,
      autoCloseTags: true,
      matchTags: true,
      autoCloseBrackets: true,
      styleActiveLine: true,
      hintOptions: { schemaInfo: this.editorHints },
      extraKeys: {
        Tab: false,
        "'<'": completeAfter,
        "'='": completeIfInTag,
        "Ctrl-Space": "autocomplete"
      },
      viewportMargin: Infinity
    });
    this.editor.refresh();
  },
  methods: {
    refreshEditor() {
      this.editor.refresh();
    },
    editorChange() {
      this.editorContent = this.editor.getValue();
    },
    reformatEditorContent() {
      this.formattedContent = beautify.html(this.editorContent, {
        indent_size: "4"
      });
      //Update the editor with the reformatted value, if editor is ready to accept (not at time of init)
      if (this.editor && this.editor.getDoc) {
        this.editor.getDoc().setValue(this.formattedContent);
      }
    },
    insertCharacter(character) {
      if (character == "¶") {
        this.editor.replaceSelection("<lb />");
      } else if (character == "…") {
        this.editor.replaceSelection('<gap reason="lost" extent="unknown" />');
      } else {
        this.editor.replaceSelection(character);
      }
      this.editorChange();
      this.editor.focus();
    },
    changeTheme(theme) {
      // Save theme selection to local storage, will be read in this.currentTheme()
      localStorage.setItem("uu.codemirror.theme.name", theme.theme);
      // Update the editor to use the selected theme
      this.editor.setOption("theme", theme.theme);
    }
  }
};
function completeAfter(cm, pred) {
  if (!pred || pred())
    setTimeout(function() {
      if (!cm.state.completionActive) cm.showHint({ completeSingle: false });
    }, 100);
  return CodeMirror.Pass;
}

function completeIfInTag(cm) {
  return completeAfter(cm, function() {
    let tok = cm.getTokenAt(cm.getCursor());
    if (
      tok.type == "string" &&
      (!/['"]/.test(tok.string.charAt(tok.string.length - 1)) ||
        tok.string.length == 1)
    )
      return false;
    let inner = CodeMirror.innerMode(cm.getMode(), tok.state).state;
    return inner.tagName;
  });
}

function assignChildrenToWordTag(tags) {
  let children = [];
  for (let tag in tags) {
    if (tag !== "w" && tag !== "name") {
      children.push(tag);
    }
  }
  tags.w.children = children;
  return children;
}
</script>
